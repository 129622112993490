import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import TxtImg from "../components/TxtImg/TxtImg"
import * as PNFStyles from "./404.module.css"

export default function PNF({ data }) {
    return (
        <Layout>
            <div className={PNFStyles.background}>
                <div className={PNFStyles.container}>
                    <TxtImg split="half" imgFirst={false} imgSrc={data.broken.childImageSharp.fluid} imgAlt="Broken lightbulb illustration">
                        <h1>Oops!</h1>
                        <h2>We can't seem to find the page you're looking for.</h2>
                        <p>Error code: 404</p>
                        <p>Here are some helpful links instead:</p>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about/">About</Link></li>
                            <li><Link to="/services/">Services</Link></li>
                            <li><Link to="/faq/">FAQ</Link></li>
                            <li><Link to="/appointments/">Appointments</Link></li>
                        </ul>
                    </TxtImg>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        broken: file(relativePath: {eq: "images/broken.png"}) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`